import { parseIntWithNVFallback, parseStringWithNAFallback, parseIntWithNAFallback } from 'sources/parsers/commons';
import update from 'immutability-helper';
import { reduce, map, omit } from 'ramda';

import Defaults from 'mangools-commons/dist/constants/Defaults';

import { READY, PROCESSING, WAITING, EXPIRED } from 'constants/BacklinkExportStates';

import { parseUrlType } from 'sources/parsers/ResultDataParser';

export const parseSuggestions = rawData => {
    const suggestionData = reduce(
        (suggestions, suggestion) => {
            return update(suggestions, {
                byIds: {
                    $merge: {
                        [suggestion.url]: {
                            url: suggestion.url,
                            type: parseUrlType(suggestion.type),
                            cost: parseIntWithNVFallback(suggestion.cost),
                            state: parseStringWithNAFallback(suggestion.state),
                            backlinkCount: suggestion.backLinks,
                        },
                    },
                },
                allIds: { $push: [suggestion.url] },
            });
        },
        { byIds: {}, allIds: [] },
        rawData.suggestions,
    );

    return {
        suggestedType: parseUrlType(rawData.suggestedType),
        ...suggestionData,
    };
};

const parseExportState = (state, downloadURL) => {
    const isUrlAvailable = !!downloadURL && downloadURL !== Defaults.NOT_AVAILABLE;

    switch (state) {
        case 100:
            return WAITING;
        case 200:
        case 300:
        case 400:
        case 500:
            return PROCESSING;
        case 600:
            return isUrlAvailable ? READY : EXPIRED;
        default:
            return WAITING;
    }
};

const parseFilter = rawFilter => {
    return omit(['flagFrameLink', 'flagImageLink', 'flagMentionLink', 'flagRedirectLink', 'flagTextLink'], rawFilter);
};

export const parseExports = rawData =>
    map(backlinkExport => {
        return {
            jobName: backlinkExport.jobName,
            state: parseExportState(backlinkExport.state, backlinkExport.downloadURL),
            downloadUrl: parseStringWithNAFallback(backlinkExport.downloadURL),
            createdAt: backlinkExport.createdAt,
            completedAt: parseIntWithNAFallback(backlinkExport.completedAt),
            request: {
                url: backlinkExport.request.url,
                filter: parseFilter(backlinkExport.request.filter),
                type: parseUrlType(backlinkExport.request.type),
            },
        };
    }, rawData);

export const parseCreate = rawData => ({
    jobName: rawData.jobName,
    state: parseExportState(rawData.state),
    downloadUrl: null, // API doesn't return this field
    createdAt: rawData.createdAt,
    completedAt: parseIntWithNAFallback(rawData.completedAt),
    request: {
        url: rawData.request.url,
        filter: parseFilter(rawData.request.filter),
        type: parseUrlType(rawData.request.type),
    },
});
