import React from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Urls from 'mangools-commons/dist/constants/Urls';
import { useSelector } from 'react-redux';
import { Image, useFocusOnMount } from 'mangools-react-components/src';

import { colorSchemeSelector } from 'selectors/uiSelectors';

import kwImage from 'images/kwfinder-icon.svg';
import serpwImage from 'images/serpwatcher-icon.svg';
import serpcImage from 'images/serpchecker-icon.svg';
import linkmImage from 'images/linkminer-icon.svg';
import spImage from 'images/siteprofiler-icon.svg';
import mnglsImage from 'images/mangools-logo-full.svg';
import mnglsImageWhite from 'images/mangools-logo-full-white.svg';
import { MiniSeoToolListMobile } from 'mangools-react-components/src/components/miniSeoTools/MiniSeoToolListMobile';

function AppPanel(props) {
    const closeRef = useFocusOnMount();
    const colorScheme = useSelector(state => colorSchemeSelector(state));

    if (props.visible === true) {
        return (
            <aside className="mg-panel is-narrow color-black font-montserrat">
                <button className="mg-close" onClick={props.onClose} title="Close panel" type="button" ref={closeRef}>
                    <FontAwesomeIcon aria-label="Close panel" icon="times" />
                </button>
                <div className="mg-panel-content mg-padding-30">
                    <a
                        href={`${Urls.MANGOOLS_ROOT_URL}?ref=menu-app-lm`}
                        className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                        rel="noopener"
                        target="_blank"
                    >
                        <Image
                            className="mg-margin-b-30"
                            lightSrc={mnglsImage}
                            darkSrc={mnglsImageWhite}
                            colorScheme={colorScheme}
                            alt="Mangools logo"
                            width="140"
                        />
                    </a>
                    <div>Juicy SEO tools you will love</div>
                    <ul className="mg-apps-list mg-margin-t-30">
                        <li>
                            <a
                                href={`${Urls.KWFINDER_APP_URL}?ref=menu-app-lm`}
                                className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                rel="noopener"
                                target="_blank"
                            >
                                <div className="color-black uk-flex uk-flex-middle">
                                    <div className="mg-app-icon mg-margin-r-10 uk-hidden-small">
                                        <img src={kwImage} alt="logo" width="18" />
                                    </div>
                                    <div>
                                        <div className="font-16 uk-text-bold">KWFinder</div>
                                        <div className="font-14 color-grey">Keyword Research</div>
                                    </div>
                                </div>
                                <span className="uk-hidden font-24">
                                    <FontAwesomeIcon icon="external-link-alt" aria-hidden="true" />
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`${Urls.SERPCHECKER_APP_URL}?ref=menu-app-lm`}
                                className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                rel="noopener"
                                target="_blank"
                            >
                                <div className="color-black uk-flex uk-flex-middle">
                                    <div className="mg-app-icon mg-margin-r-10 uk-hidden-small">
                                        <img src={serpcImage} alt="logo" width="18" />
                                    </div>
                                    <div>
                                        <div className="font-16 uk-text-bold">SERPChecker</div>
                                        <div className="font-14 color-grey">SERP Analysis</div>
                                    </div>
                                </div>
                                <span className="uk-hidden font-24">
                                    <FontAwesomeIcon icon="external-link-alt" aria-hidden="true" />
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`${Urls.SERPWATCHER_APP_URL}?ref=menu-app-lm`}
                                className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                rel="noopener"
                                target="_blank"
                            >
                                <div className="color-black uk-flex uk-flex-middle">
                                    <div className="mg-app-icon mg-margin-r-10 uk-hidden-small">
                                        <img src={serpwImage} alt="logo" width="18" />
                                    </div>
                                    <div>
                                        <div className="font-16 uk-text-bold">SERPWatcher</div>
                                        <div className="font-14 color-grey">Rank Tracking</div>
                                    </div>
                                </div>
                                <span className="uk-hidden font-24">
                                    <FontAwesomeIcon icon="external-link-alt" aria-hidden="true" />
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`${Urls.LINKMINER_APP_URL}?ref=menu-app-lm`}
                                className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                rel="noopener"
                                target="_blank"
                            >
                                <div className="color-black uk-flex uk-flex-middle">
                                    <div className="mg-app-icon mg-margin-r-10 uk-hidden-small">
                                        <img src={linkmImage} alt="logo" width="18" />
                                    </div>
                                    <div>
                                        <div className="font-16 uk-text-bold">LinkMiner</div>
                                        <div className="font-14 color-grey">Backlink Analysis</div>
                                    </div>
                                </div>
                                <span className="uk-hidden font-24">
                                    <FontAwesomeIcon icon="external-link-alt" aria-hidden="true" />
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`${Urls.SITEPROFILER_APP_URL}?ref=menu-app-lm`}
                                className="uk-visible-hover uk-flex uk-flex-space-between uk-flex-middle"
                                rel="noopener"
                                target="_blank"
                            >
                                <div className="color-black uk-flex uk-flex-middle">
                                    <div className="mg-app-icon mg-margin-r-10 uk-hidden-small">
                                        <img src={spImage} alt="logo" width="18" />
                                    </div>
                                    <div>
                                        <div className="font-16 uk-text-bold">SiteProfiler</div>
                                        <div className="font-14 color-grey">SEO metrics &amp; Insights</div>
                                    </div>
                                </div>
                                <span className="uk-hidden font-24">
                                    <FontAwesomeIcon icon="external-link-alt" aria-hidden="true" />
                                </span>
                            </a>
                        </li>
                    </ul>
                    <hr />
                    <h2 className="font-18 uk-text-bold">Mini SEO tools</h2>
                    <MiniSeoToolListMobile />
                </div>
            </aside>
        );
    } else {
        return null;
    }
}

AppPanel.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default AppPanel;
