const ActionTypes = {
    // ========================================================================
    // DATA ACTIONS
    // ========================================================================

    // Redux persist load completed
    DATA_REHYDRATATION_COMPLETE: 'DATA_REHYDRATATION_COMPLETE',

    // URL metrics data
    DATA_URL_DATA_ERROR: 'DATA_URL_DATA_ERROR',
    DATA_URL_DATA_FETCHING: 'DATA_URL_DATA_FETCHING',
    DATA_URL_DATA_RECEIVED: 'DATA_URL_DATA_RECEIVED',
    DATA_URL_DATA_REQUESTED: 'DATA_URL_DATA_REQUESTED',

    // Results data
    DATA_MORE_RESULTS_ERROR: 'DATA_MORE_RESULTS_ERROR',
    DATA_MORE_RESULTS_FETCHING: 'DATA_MORE_RESULTS_FETCHING',
    DATA_MORE_RESULTS_RECEIVED: 'DATA_MORE_RESULTS_RECEIVED',
    DATA_MORE_RESULTS_REQUESTED: 'DATA_MORE_RESULTS_REQUESTED',
    DATA_MORE_RESULTS_SKIPPED: 'DATA_MORE_RESULTS_SKIPPED',
    DATA_RESULTS_ERROR: 'DATA_RESULTS_ERROR',
    DATA_RESULTS_EXPORT_ERROR: 'DATA_RESULTS_EXPORT_ERROR',
    DATA_RESULTS_EXPORT_RECEIVED: 'DATA_RESULTS_EXPORT_RECEIVED',
    DATA_RESULTS_EXPORT_REQUESTED: 'DATA_RESULTS_EXPORT_REQUESTED',
    DATA_RESULTS_FAVORITE_FLAG_SET_ERROR: 'DATA_RESULTS_FAVORITE_FLAG_SET_ERROR',
    DATA_RESULTS_FAVORITE_FLAG_SET_FETCHING: 'DATA_RESULTS_FAVORITE_FLAG_SET_FETCHING',
    DATA_RESULTS_FAVORITE_FLAG_SET_RECEIVED: 'DATA_RESULTS_FAVORITE_FLAG_SET_RECEIVED',
    DATA_RESULTS_FAVORITE_FLAG_SET_REQUESTED: 'DATA_RESULTS_FAVORITE_FLAG_SET_REQUESTED',
    DATA_RESULTS_FAVORITE_LIST_RECEIVED: 'DATA_RESULTS_FAVORITE_LIST_RECEIVED',
    DATA_RESULTS_FAVORITE_LIST_REQUESTED: 'DATA_RESULTS_FAVORITE_LIST_REQUESTED',
    DATA_RESULTS_FETCHING: 'DATA_RESULTS_FETCHING',
    DATA_RESULTS_RECEIVED: 'DATA_RESULTS_RECEIVED',
    DATA_RESULTS_REQUESTED: 'DATA_RESULTS_REQUESTED',

    // User data
    DATA_USER_CHECK_STATUS_ERROR: 'DATA_USER_CHECK_STATUS_ERROR',
    DATA_USER_CHECK_STATUS_SKIPPED: 'DATA_USER_CHECK_STATUS_SKIPPED',
    DATA_USER_DATA_ERROR: 'DATA_USER_DATA_ERROR',
    DATA_USER_DATA_FETCHING: 'DATA_USER_DATA_FETCHING',
    DATA_USER_DATA_RECEIVED: 'DATA_USER_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_ERROR: 'DATA_USER_LIMIT_DATA_ERROR',
    DATA_USER_LIMIT_DATA_FETCHING: 'DATA_USER_LIMIT_DATA_FETCHING',
    DATA_USER_LIMIT_DATA_RECEIVED: 'DATA_USER_LIMIT_DATA_RECEIVED',
    DATA_USER_LIMIT_DATA_REQUESTED: 'DATA_USER_LIMIT_DATA_REQUESTED',
    DATA_USER_LIMIT_DATA_SKIPPED: 'DATA_USER_LIMIT_DATA_SKIPPED',
    DATA_USER_LOGOUT_ERROR: 'DATA_USER_LOGOUT_ERROR',
    DATA_USER_LOGOUT_RECEIVED: 'DATA_USER_LOGOUT_RECEIVED',
    DATA_USER_LOGOUT_REQUESTED: 'DATA_USER_LOGOUT_REQUESTED',
    DATA_USER_AUTH_TOKENS_RECEIVED: 'DATA_USER_AUTH_TOKENS_RECEIVED',
    DATA_USER_DATA_SKIPPED: 'DATA_USER_DATA_SKIPPED',

    // History data
    DATA_HISTORY_ERROR: 'DATA_HISTORY_ERROR',
    DATA_HISTORY_FETCHING: 'DATA_HISTORY_FETCHING',
    DATA_HISTORY_RECEIVED: 'DATA_HISTORY_RECEIVED',
    DATA_HISTORY_REQUESTED: 'DATA_HISTORY_REQUESTED',
    DATA_HISTORY_SKIPPED: 'DATA_HISTORY_SKIPPED',
    DATA_HISTORY_DELETE_ERROR: 'DATA_HISTORY_DELETE_ERROR',
    DATA_HISTORY_DELETE_RECEIVED: 'DATA_HISTORY_DELETE_RECEIVED',
    DATA_HISTORY_DELETE_REQUESTED: 'DATA_HISTORY_DELETE_REQUESTED',

    // Favorite lists data
    DATA_FAVORITE_LISTS_DELETE_CANCELLED: 'DATA_FAVORITE_LISTS_DELETE_CANCELLED',
    DATA_FAVORITE_LISTS_DELETE_ERROR: 'DATA_FAVORITE_LISTS_DELETE_ERROR',
    DATA_FAVORITE_LISTS_DELETE_FETCHING: 'DATA_FAVORITE_LISTS_DELETE_FETCHING',
    DATA_FAVORITE_LISTS_DELETE_RECEIVED: 'DATA_FAVORITE_LISTS_DELETE_RECEIVED',
    DATA_FAVORITE_LISTS_DELETE_REQUESTED: 'DATA_FAVORITE_LISTS_DELETE_REQUESTED',
    DATA_FAVORITE_LISTS_ERROR: 'DATA_FAVORITE_LISTS_ERROR',
    DATA_FAVORITE_LISTS_FETCHING: 'DATA_FAVORITE_LISTS_FETCHING',
    DATA_FAVORITE_LISTS_RECEIVED: 'DATA_FAVORITE_LISTS_RECEIVED',
    DATA_FAVORITE_LISTS_REQUESTED: 'DATA_FAVORITE_LISTS_REQUESTED',
    DATA_FAVORITE_LISTS_SKIPPED: 'DATA_FAVORITE_LISTS_SKIPPED',

    // Announcement message data
    DATA_ANNOUNCEMENTS_ERROR: 'DATA_ANNOUNCEMENTS_ERROR',
    DATA_ANNOUNCEMENTS_FETCHING: 'DATA_ANNOUNCEMENTS_FETCHING',
    DATA_ANNOUNCEMENTS_RECEIVED: 'DATA_ANNOUNCEMENTS_RECEIVED',

    // Backlinks export
    DATA_BACKLINK_EXPORT_SUGGESTIONS_ERROR: 'DATA_BACKLINK_EXPORT_SUGGESTIONS_ERROR',
    DATA_BACKLINK_EXPORT_SUGGESTIONS_RECEIVED: 'DATA_BACKLINK_EXPORT_SUGGESTIONS_RECEIVED',
    DATA_BACKLINK_EXPORT_SUGGESTIONS_REQUESTED: 'DATA_BACKLINK_EXPORT_SUGGESTIONS_REQUESTED',
    DATA_BACKLINK_EXPORT_SUGGESTIONS_RESET: 'DATA_BACKLINK_EXPORT_SUGGESTIONS_RESET',
    DATA_BACKLINK_EXPORTS_FETCHING: 'DATA_BACKLINK_EXPORTS_FETCHING',
    DATA_BACKLINK_EXPORTS_REQUESTS_START: 'DATA_BACKLINK_EXPORTS_REQUESTS_START',
    DATA_BACKLINK_EXPORTS_REQUESTS_STOP: 'DATA_BACKLINK_EXPORTS_REQUESTS_STOP',
    DATA_BACKLINK_EXPORTS_REQUESTED: 'DATA_BACKLINK_EXPORTS_REQUESTED:',
    DATA_BACKLINK_EXPORTS_RECEIVED: 'DATA_BACKLINK_EXPORTS_RECEIVED',
    DATA_BACKLINK_EXPORTS_ERROR: 'DATA_BACKLINK_EXPORTS_ERROR',
    DATA_NEW_BACKLINK_EXPORT_FETCHING: 'DATA_NEW_BACKLINK_EXPORT_FETCHING',
    DATA_NEW_BACKLINK_EXPORT_REQUESTED: 'DATA_NEW_BACKLINK_EXPORT_REQUESTED',
    DATA_NEW_BACKLINK_EXPORT_RECEIVED: 'DATA_NEW_BACKLINK_EXPORT_RECEIVED',
    DATA_NEW_BACKLINK_EXPORT_ERROR: 'DATA_NEW_BACKLINK_EXPORT_ERROR',

    DATA_USER_SET_UNLEASH_SESSION: 'DATA_USER_SET_UNLEASH_SESSION',

    // ========================================================================
    // UI ACTIONS
    // ========================================================================

    // Messsages

    UI_ALL_CLOSE: 'UI_ALL_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_CLOSE: 'UI_MESSAGES_ACCESS_DENIED_CLOSE',
    UI_MESSAGES_ACCESS_DENIED_SHOW: 'UI_MESSAGES_ACCESS_DENIED_SHOW',
    UI_MESSAGES_DELETE_CONFIRMATION_CLOSE: 'UI_MESSAGES_DELETE_CONFIRMATION_CLOSE',
    UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM: 'UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM',
    UI_MESSAGES_DELETE_CONFIRMATION_SHOW: 'UI_MESSAGES_DELETE_CONFIRMATION_SHOW',
    UI_MESSAGES_FAILURE_CLOSE: 'UI_MESSAGES_FAILURE_CLOSE',
    UI_MESSAGES_FAILURE_SHOW: 'UI_MESSAGES_FAILURE_SHOW',
    UI_MESSAGES_LOGGED_OUT_CLOSE: 'UI_MESSAGES_LOGGED_OUT_CLOSE',
    UI_MESSAGES_LOGGED_OUT_SHOW: 'UI_MESSAGES_LOGGED_OUT_SHOW',
    UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: 'UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE',
    UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: 'UI_MESSAGES_NEED_TO_SIGN_IN_SHOW',
    UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_CLOSE: 'UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_CLOSE',
    UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_SHOW: 'UI_MESSAGES_NEED_TO_UPGRADE_ACCOUNT_SHOW',
    UI_MESSAGES_NO_CONNECTION_CLOSE: 'UI_MESSAGES_NO_CONNECTION_CLOSE',
    UI_MESSAGES_NO_CONNECTION_SHOW: 'UI_MESSAGES_NO_CONNECTION_SHOW',
    UI_MESSAGES_PRICING_CLOSE: 'UI_MESSAGES_PRICING_CLOSE',
    UI_MESSAGES_PRICING_SHOW: 'UI_MESSAGES_PRICING_SHOW',
    UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: 'UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE',
    UI_MESSAGES_URL_TYPE_INFO_CLOSE: 'UI_MESSAGES_URL_TYPE_INFO_CLOSE',
    UI_MESSAGES_URL_TYPE_INFO_SHOW: 'UI_MESSAGES_URL_TYPE_INFO_SHOW',
    UI_MESSAGES_SHORTCUTS_CLOSE: 'UI_MESSAGES_SHORTCUTS_CLOSE',
    UI_MESSAGES_SHORTCUTS_SHOW: 'UI_MESSAGES_SHORTCUTS_SHOW',
    UI_MESSAGES_CREATE_EXPORT_SUCCESS_SHOW: 'UI_MESSAGES_CREATE_EXPORT_SUCCESS_SHOW',
    UI_MESSAGES_CREATE_EXPORT_SUCCESS_CLOSE: 'UI_MESSAGES_CREATE_EXPORT_SUCCESS_CLOSE',
    UI_MESSAGES_NOT_EXISTING_DOMAIN_SHOW: 'UI_MESSAGES_NOT_EXISTING_DOMAIN_SHOW',
    UI_MESSAGES_NOT_EXISTING_DOMAIN_CLOSE: 'UI_MESSAGES_NOT_EXISTING_DOMAIN_CLOSE',

    // Misc
    UI_MISC_COLOR_SCHEME_SET: 'UI_MISC_COLOR_SCHEME_SET',
    UI_MISC_NAVIGATED_INTERNALLY_SET: 'UI_MISC_NAVIGATED_INTERNALLY_SET',
    UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: 'UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET',
    UI_MISC_NOTIFICATIONS_CLOSE_ALL: 'UI_MISC_NOTIFICATIONS_CLOSE_ALL',
    UI_MISC_NOTIFICATIONS_ERROR_SHOW: 'UI_MISC_NOTIFICATIONS_ERROR_SHOW',
    UI_MISC_NOTIFICATIONS_INFO_SHOW: 'UI_MISC_NOTIFICATIONS_INFO_SHOW',
    UI_MISC_NOTIFICATIONS_SUCCESS_SHOW: 'UI_MISC_NOTIFICATIONS_SUCCESS_SHOW',
    UI_MISC_ONLINE_STATUS_SET: 'UI_MISC_ONLINE_STATUS_SET',
    UI_MISC_RESULT_TABLE_HORIZONTAL_SCROLL_SET: 'UI_MISC_RESULT_TABLE_HORIZONTAL_SCROLL_SET',

    // Dropdowns
    UI_DROPDOWNS_APP_CLOSE: 'UI_DROPDOWNS_APP_CLOSE',
    UI_DROPDOWNS_APP_SHOW: 'UI_DROPDOWNS_APP_SHOW',
    UI_DROPDOWNS_EXPORT_CLOSE: 'UI_DROPDOWNS_EXPORT_CLOSE',
    UI_DROPDOWNS_EXPORT_SHOW: 'UI_DROPDOWNS_EXPORT_SHOW',
    UI_DROPDOWNS_HELP_CLOSE: 'UI_DROPDOWNS_HELP_CLOSE',
    UI_DROPDOWNS_HELP_SHOW: 'UI_DROPDOWNS_HELP_SHOW',

    // Filter
    UI_FILTER_ADVANCED_ACTIVE_SET: 'UI_FILTER_ADVANCED_ACTIVE_SET',
    UI_FILTER_QUICK_LINK_REL_TYPE_SET: 'UI_FILTER_QUICK_LINK_REL_TYPE_SET',
    UI_FILTER_QUICK_LINK_STATUS_TYPE_SET: 'UI_FILTER_QUICK_LINK_STATUS_TYPE_SET',
    UI_FILTER_QUICK_SEARCH_SET: 'UI_FILTER_QUICK_SEARCH_SET',
    UI_FILTER_RESET: 'UI_FILTER_RESET',
    UI_FILTER_SETTINGS_SET: 'UI_FILTER_SETTINGS_SET',
    UI_FILTER_VISIBILITY_SET: 'UI_FILTER_VISIBILITY_SET',
    UI_FILTER_VISIBILITY_TOGGLE: 'UI_FILTER_VISIBILITY_TOGGLE',

    // Dashboard
    UI_DASHBOARD_CURRENT_DATA_SOURCE_SET: 'UI_DASHBOARD_CURRENT_DATA_SOURCE_SET',
    UI_DASHBOARD_CURRENT_RESULT_SET: 'UI_DASHBOARD_CURRENT_RESULT_SET',
    UI_DASHBOARD_RESET: 'UI_DASHBOARD_RESET',
    UI_DASHBOARD_SORTING_SETTINGS_SET: 'UI_DASHBOARD_SORTING_SETTINGS_SET',
    UI_DASHBOARD_WEBSITE_PREVIEW_SIZE_SET: 'UI_DASHBOARD_WEBSITE_OVERVIEW_STATE_SET',
    UI_DASHBOARD_WEBSITE_PREVIEW_VISIBILITY_SET: 'UI_DASHBOARD_WEBSITE_PREVIEW_VISIBILITY_SET',

    // UI DEFAULTS ACTIONS

    // Modals

    // Panels
    UI_PANELS_APP_CLOSE: 'UI_PANELS_APP_CLOSE',
    UI_PANELS_APP_SHOW: 'UI_PANELS_APP_SHOW',
    UI_PANELS_FAVORITES_CLOSE: 'UI_PANELS_FAVORITES_CLOSE',
    UI_PANELS_FAVORITES_SHOW: 'UI_PANELS_FAVORITES_SHOW',
    UI_PANELS_HISTORY_CLOSE: 'UI_PANELS_HISTORY_CLOSE',
    UI_PANELS_HISTORY_SHOW: 'UI_PANELS_HISTORY_SHOW',

    // Create export
    UI_CREATE_EXPORT_FILTER_RESET: 'UI_CREATE_EXPORT_FILTER_RESET',
    UI_CREATE_EXPORT_FILTER_ACTIVE_SET: 'UI_CREATE_EXPORT_FILTER_ACTIVE_SET',
    UI_CREATE_EXPORT_FILTER_SETTINGS_SET: 'UI_CREATE_EXPORT_FILTER_SETTINGS_SET',
    UI_CREATE_EXPORT_FILTER_VISIBILITY_TOGGLE: 'UI_CREATE_EXPORT_FILTER_VISIBILITY_TOGGLE',
    UI_CREATE_EXPORT_URL_SET: 'UI_CREATE_EXPORT_URL_SET',
    UI_CREATE_EXPORT_LIMIT_SET: 'UI_CREATE_EXPORT_LIMIT_SET',
    UI_CREATE_EXPORT_SELECTED_SUGGESTION_URL_SET: 'UI_CREATE_EXPORT_SELECTED_SUGGESTION_URL_SET',

    // ========================================================================
    // OTHER ACTIONS
    // ========================================================================

    // Analytics
    ANALYTICS_TRACK: 'ANALYTICS_TRACK',

    // Params
    PARAMS_SET: 'PARAMS_SET',

    // Router
    ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
    ROUTER_NAVIGATION_BACK_REQUESTED: 'ROUTER_NAVIGATION_BACK_REQUESTED',
    ROUTER_NAVIGATION_REPLACE_REQUESTED: 'ROUTER_NAVIGATION_REPLACE_REQUESTED',
    ROUTER_NAVIGATION_REQUESTED: 'ROUTER_NAVIGATION_REQUESTED',
    ROUTER_AUTH_TOKENS_HIDE_REQUESTED: 'ROUTER_AUTH_TOKENS_HIDE_REQUESTED',
};

export default ActionTypes;
