import Urls from 'mangools-commons/dist/constants/Urls';

export const HELP_DROPDOWN_ITEMS = [
    {
        label: 'Guides on our blog',
        items: [
            {
                label: 'How to: LinkMiner guide',
                link: `${Urls.LINKMINER_GUIDE_URL}?ref=header-app-lm`,
                description: '11 min read',
            },
            {
                label: 'Link building guide',
                link: `${Urls.MANGOOLS_60_LINKBUILDING_TECHNIQUES_URL}?ref=header-app-lm`,
                description: '36 min read',
            },
            {
                label: 'SEO guide for beginners',
                link: `${Urls.MANGOOLS_SEO_ACADEMY_URL}?ref=header-app-lm`,
                description: '129 min read',
            },
        ],
    },
    {
        label: 'Other',
        items: [
            {
                label: 'Mangools Blog',
                link: `${Urls.MANGOOLS_BLOG_URL}?ref=header-app-lm`,
            },
            {
                label: 'Affiliate program',
                link: `${Urls.MANGOOLS_AFFILIATE_URL}?ref=header-app-lm`,
            },
            {
                label: 'FAQ',
                link: `${Urls.MANGOOLS_FAQ_URL}?ref=header-app-lm`,
            },
        ],
    },
];
