import queryString from 'query-string';
import Paths from 'mangools-commons/dist/constants/Paths';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app';
import RoutePaths from 'constants/RoutePaths';
import { routerGoBack, routerNavigate, routerReplace } from 'mangools-react-components/src';

class RouterService {
    static navigate(route, params = {}) {

        const searchParams = queryString.stringify(params);
        const data = {
            pathname: route,
            search: searchParams ? `?${searchParams}` : '',
        };

        routerNavigate(data);
    }

    static replace(route, params = {}) {
        const searchParams = queryString.stringify(params);
        const data = {
            pathname: route,
            search: searchParams ? `?${searchParams}` : '',
        };
        routerReplace(data);
    }

    static replaceWithParams(data) {
        routerReplace(data);
    }

    static navigateToRoot() {
        routerNavigate({ pathname: RoutePaths.ROOT, search: '' });
    }

    static navigateBack() {
        routerGoBack();
    }

    static isSame(pathname, routePath) {
        return pathname === routePath || pathname === `/${routePath}`;
    }

    static redirect(url, query = {}) {
        const stringifiedQuery = queryString.stringify(query);
        window.location.replace(
            `${url}${stringifiedQuery.length > 0 ? `?${stringifiedQuery}` : ''}`
        );
    }

    static redirectToAuth(redirectUrl) {
        RouterService.redirect(
            `${APP_CONFIG.MANGOOLS_API_HOST}${Paths.MANGOOLS_AUTH_PATH}`,
            { redirect: redirectUrl }
        );
    }
}

export default RouterService;
