import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import HumanService from 'mangools-commons/dist/services/HumanService';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

import { parseSuggestions, parseExports, parseCreate } from 'sources/parsers/BacklinkExportParser';

import BacklinkExportService from 'services/BacklinkExportService';

const ENDPOINT = '/exports';

class BacklinkExportSource {
    static getData({ accessToken }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Mangools-Client': 'app',
                'X-Human-Token': HumanService.gen(),
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseExports(data),
                        }))
                        .catch(e => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: e.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static create({ accessToken }, { url, filterSettings, limit }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Mangools-Client': 'app',
                'X-Human-Token': HumanService.gen(),
            },
            body: JSON.stringify({ url, limit, filter: BacklinkExportService.formatFilterSettings(filterSettings) }),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseCreate(data),
                        }))
                        .catch(e => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: e.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static suggestions({ accessToken }, url) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}/suggest`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Mangools-Client': 'app',
                'X-Human-Token': HumanService.gen(),
            },
            body: JSON.stringify({ url }),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseSuggestions(data),
                        }))
                        .catch(e => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: e.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default BacklinkExportSource;
