import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import forEach from 'ramda/src/forEach';
import { ReduxMiddlewareService } from 'mangools-commons/dist/services/ReduxMiddlewareService';
import GTMService from 'mangools-commons/dist/services/GTMService';

import rootReducer from 'reducers/rootReducer';
import { rehydrationComplete } from 'actions/dataActions';
import rootSaga from 'sagas/rootSaga';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

// Middleware setting
const sagaMiddleware = createSagaMiddleware();
const analyticsMiddleware = ReduxMiddlewareService.createAnalyticsMiddleware(GTMService.dataLayer.push);
const middlewares = [sagaMiddleware, analyticsMiddleware];

// Constants
const LS_PREFIX = 'LM';
const WHITELIST = ['ui', 'user'];

// Create compone enhancer with Redux devtools in development
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            maxAge: 9999,
            shouldCatchErrors: true,
        })
        : compose;
/* eslint-enable no-underscore-dangle */

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// Store configuration
const configureStore = initialState => createStore(rootReducer, initialState, enhancer);

// Get configured store
const store = configureStore();

// Save and Load transforms

const filterUiReducer = createFilter('ui', ['filter.settings', 'dashboard.websitePreview', 'misc.colorScheme']);

const filterUserReducer = createFilter('user', ['ssoTicket', 'loginToken']);

// Setup redux persist

persistStore(
    store,
    {
        keyPrefix: `${LS_PREFIX}_v${APP_CONFIG.LOCAL_STORAGE_VERSION}_`,
        transforms: [filterUiReducer, filterUserReducer],
        whitelist: WHITELIST,
    },
    () => {
        store.dispatch(rehydrationComplete());
    },
);

// Utility functions

export const cleanPreviousState = () => {
    const oldKeyPrefix =
        APP_CONFIG.LOCAL_STORAGE_VERSION > 1 ? `${LS_PREFIX}_v${APP_CONFIG.LOCAL_STORAGE_VERSION - 1}` : LS_PREFIX;

    forEach(item => {
        const key = `${oldKeyPrefix}_${item}`;
        localStorage.removeItem(key);
    }, WHITELIST);
};

// Run root saga
store.runSaga = sagaMiddleware.run(rootSaga);
store.close = () => store.dispatch(END);

export default store;
