import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoonAuto } from 'mangools-commons/dist/constants/Icons';

import {
    faAnchor,
    faArrowRight,
    faArrowUp,
    faBars,
    faBullseye,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCompress,
    faCopy,
    faDesktop,
    faDownload,
    faFolder,
    faFolderPlus,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExpand,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faGift,
    faGlobeAmericas,
    faHistory,
    faHourglassStart,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLevelUpAlt,
    faLink,
    faList,
    faLock,
    faMapMarkerAlt,
    faMinus,
    faMinusSquare,
    faMobileAlt,
    faMoon,
    faMousePointer,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlug,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faQuestion,
    faReply,
    faRocket,
    faSearch,
    faShareAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faStar as fasFaStar,
    faSun,
    faSync,
    faTimes,
    faUnlock,
    faUser,
    faUserCog,
    faWaveSquare,
} from '@fortawesome/free-solid-svg-icons';

import {
    faClock,
    faClone,
    faCreditCard,
    faDotCircle,
    faFileExcel,
    faHandPaper,
    faLightbulb,
    faQuestionCircle,
    faStar as farFaStar,
    faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const faPath = {
    prefix: 'fac',
    iconName: 'path',
    icon: [
        24,
        24,
        [],
        'e002',
        // eslint-disable-next-line max-len
        'M3.8,18.1c0,1.1-0.8,2-1.9,2c-1.1,0-1.9-0.8-1.9-2s0.8-1.9,1.9-1.9C3,16.2,3.8,16.9,3.8,18.1z M16.2,1.7L8.9,22.3H5.5l7.3-20.7H16.2z M20.8,2.6l0,2.4l2.1-1.2l1.2,2L21.8,7L24,8.1l-1.2,2l-2.1-1.3l0,2.4h-2.3l0.1-2.4l-2.1,1.3l-1.2-2L17.5,7l-2.1-1.2l1.2-2L18.6,5l-0.1-2.4H20.8z',
    ],
};

const faFolders = {
    prefix: 'fac',
    iconName: 'folders',
    icon: [
        640,
        640,
        [],
        'e003',
        // eslint-disable-next-line max-len
        'M96,336V128H48c-26.51,0-48,21.49-48,48v288c0,26.51,21.49,48,48,48h416c26.51,0,48-21.49,48-48v-48H176c-44.11,0-80-35.89-80-80zM592,64H400L336,0H176c-26.51,0-48,21.49-48,48v288c0,26.51,21.49,48,48,48h416c26.51,0,48-21.49,48-48V112c0-26.51-21.49-48-48-48z',
    ],
};

library.add(
    faAnchor,
    faArrowRight,
    faArrowUp,
    faBars,
    faBullseye,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faClone,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCompress,
    faCopy,
    faCreditCard,
    faDesktop,
    faDotCircle,
    faDownload,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExpand,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileExcel,
    faFolder,
    faFolders,
    faFolderPlus,
    faGift,
    faGlobeAmericas,
    faGoogle,
    faHandPaper,
    faHistory,
    faHourglassStart,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLevelUpAlt,
    faLightbulb,
    faLink,
    faList,
    faLock,
    faMapMarkerAlt,
    faMinus,
    faMinusSquare,
    faMobileAlt,
    faMoon,
    faMoonAuto,
    faMousePointer,
    faPath,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlug,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faQuestion,
    faQuestionCircle,
    faReply,
    faRocket,
    faSearch,
    faShareAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSun,
    faSync,
    faTimes,
    faTrashAlt,
    faUnlock,
    faUser,
    faUserCog,
    faWaveSquare,
    farFaStar,
    fasFaStar,
);
