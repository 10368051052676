import React from 'react';
import { bool, func, number, oneOf, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Favicon } from 'mangools-react-components/src';
import classnames from 'classnames';

import UrlService from 'mangools-commons/dist/services/UrlService';
import DataSourceTypes from 'constants/DataSourceTypes';

function HistoryPanelItem(props) {
    const handleClick = () => {
        props.onClick({
            linksPerDomain: props.linksPerDomain,
            source: props.source,
            url: props.url,
        });
    };

    const renderSource = () => {
        switch (props.source) {
            case DataSourceTypes.ALL_LINKS: {
                return <span className="mg-label is-blue is-small font-10">All</span>;
            }
            case DataSourceTypes.NEW_LINKS: {
                return <span className="mg-label is-green is-small font-10">New</span>;
            }
            case DataSourceTypes.LOST_LINKS: {
                return <span className="mg-label is-red is-small font-10">Lost</span>;
            }
            default: {
                return null;
            }
        }
    };

    const renderLinksPerDomainIcon = () => {
        if (props.linksPerDomain === 1) {
            return (
                <span className="mg-margin-l-10 color-grey" title="One link per ref. domain">
                    <FontAwesomeIcon icon="link" />
                </span>
            );
        } else {
            return (
                <span className="mg-margin-l-10 color-grey" title="Multiple links per ref. domain">
                    <FontAwesomeIcon icon="link" />
                    <span className="font-10">
                        <FontAwesomeIcon icon="link" />
                    </span>
                </span>
            );
        }
    };

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div
            className={classnames('mg-results-tr uk-flex cursor-pointer uk-flex uk-flex-middle', {
                'is-preselected': props.isPreselected,
            })}
            onClick={handleClick}
            role="listitem"
        >
            <div className="mg-results-td is-60 uk-flex uk-flex-middle">
                <Favicon alt="#" domain={UrlService.getRoot(props.url, false)} size={16} />
            </div>
            <div className="mg-results-td uk-flex-item-auto mg-truncate">
                <span className="font-16 mg-truncate ln-12" title={props.url}>
                    {props.url}
                </span>
                <div className="mg-margin-t-5">
                    <span className="mg-marign-r-5">{renderSource()}</span>
                    {renderLinksPerDomainIcon()}
                </div>
            </div>
        </div>
    );

    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

HistoryPanelItem.propTypes = {
    linksPerDomain: number.isRequired,
    onClick: func.isRequired,
    source: oneOf([DataSourceTypes.ALL_LINKS, DataSourceTypes.NEW_LINKS, DataSourceTypes.LOST_LINKS]),
    url: string.isRequired,
    isPreselected: bool.isRequired,
};

export default React.memo(HistoryPanelItem);
