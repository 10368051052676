import CSV from 'comma-separated-values';
import CSVExportService from 'mangools-commons/dist/services/CSVExportService';

class ResultExportService {
    static export({ results, includeMetrics = false }) {
        let columns = [];
        let data = [];

        if (includeMetrics === false) {
            columns = ['Backlink URL'];
            data = results.map(result => [result.sourceUrl]);
        } else {
            columns = [
                'Backlink URL',
                'Title',
                'Target URL',
                'Anchor',
                'Citation Flow',
                'Trust Flow',
                'Website Popularity Rank',
                'Facebook Shares',
                'Link Strength',
                'External Links',
                'Internal Links',
                'Flag: Alt',
                'Flag: Deleted',
                'Flag: Frame',
                'Flag: Image',
                'Flag: Mention',
                'Flag: NoFollow',
                'Flag: Redirect',
            ];

            data = results.map(result => [
                result.sourceUrl,
                CSVExportService.formatEmptyValue(result.title),
                result.targetUrl,
                CSVExportService.formatEmptyValue(result.anchor),
                CSVExportService.formatEmptyValue(result.citationFlow),
                CSVExportService.formatEmptyValue(result.trustFlow),
                CSVExportService.formatEmptyValue(result.topRank),
                CSVExportService.formatEmptyValue(result.facebookShares),
                result.linkStrength,
                CSVExportService.formatEmptyValue(result.externalLinks),
                CSVExportService.formatEmptyValue(result.internalLinks),
                result.flags.alt,
                result.flags.deleted,
                result.flags.frame,
                result.flags.image,
                result.flags.mention,
                result.flags.noFollow,
                result.flags.redirect,
            ]);
        }

        return CSV.encode(data, {
            header: columns,
        });
    }
}

export default ResultExportService;
