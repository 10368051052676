// const PARSED_DATA_EXAMPLE = {
//      citationFlow: 12,
//      externalBackLinks: 39222,
//      id: 2703,
//      refDomains: 1112,
//      refIPs: 1332,
//      trustFlow: 23,
// };

import isNil from 'ramda/src/isNil';
import Defaults from 'mangools-commons/dist/constants/Defaults';
import { parseIntWithNAFallback } from 'sources/parsers/commons';

const isMajesticPresent = item => !isNil(item.m.majestic);

/* eslint-disable max-len */
export const parse = rawData => {
    const result = rawData[0];

    return {
        citationFlow: isMajesticPresent(result)
            ? parseIntWithNAFallback(result.m.majestic.v.CitationFlow)
            : Defaults.NOT_AVAILABLE,
        externalBackLinks: isMajesticPresent(result)
            ? parseIntWithNAFallback(result.m.majestic.v.ExtBackLinks)
            : Defaults.NOT_AVAILABLE,
        id: result._id, // eslint-disable-line no-underscore-dangle
        refDomains: isMajesticPresent(result)
            ? parseIntWithNAFallback(result.m.majestic.v.RefDomains)
            : Defaults.NOT_AVAILABLE,
        refIPs: isMajesticPresent(result) ? parseIntWithNAFallback(result.m.majestic.v.RefIPs) : Defaults.NOT_AVAILABLE,
        trustFlow: isMajesticPresent(result)
            ? parseIntWithNAFallback(result.m.majestic.v.TrustFlow)
            : Defaults.NOT_AVAILABLE,
        url: result.url,
    };
};
/* eslint-enable max-len */
