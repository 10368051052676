import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';

import ShortcutService from 'mangools-commons/dist/services/ShortcutService';
import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';
import { Alert } from 'mangools-react-components/src';

import { closeAll, closeShortcutsMessage, showShortcutsMessage } from 'actions/uiActions';

import {
    areShortcutsDisabledSelector,
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    shortcutsMessageVisibilitySelector,
} from 'selectors/uiSelectors';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import { announcementMesageVisibilitySelector } from 'selectors/dataSelectors';

import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';

const LayoutContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const onlineStatus = useSelector(onlineStatusSelector);
    const mobileOverlayVisibility = useSelector(mobileOverlayVisibilitySelector);
    const overlayVisibility = useSelector(overlayVisibilitySelector);
    const topLevelMessageVisible = useSelector(
        (state) =>
            newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
    );
    const shortcutsMessageVisible = useSelector(shortcutsMessageVisibilitySelector);
    const areShortcutsDisabled = useSelector(areShortcutsDisabledSelector);

    const appClasses = classnames({
        'is-offline': onlineStatus === false,
        'linkminer-app': true,
        'is-messaged': topLevelMessageVisible,
    });

    const handleToggleShortcuts = () => {
        if (shortcutsMessageVisible) {
            dispatch(closeShortcutsMessage());
        } else {
            dispatch(showShortcutsMessage());
        }
    };

    const renderHotkeys = (areShortcutsDisabled) => {
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [SHOW_SHORTCUTS_TOGGLE]: { func: handleToggleShortcuts },
                [CLOSE_ALL]: { func: () => dispatch(closeAll()), preventDisable: true },
            },
            areShortcutsDisabled,
            shortcuts,
        });

        return <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers}/>;
    };

    return (
        <>
            {renderHotkeys(areShortcutsDisabled)}
            <div className={appClasses}>
                <TopLevelMessageContainer/>
                <NavbarContainer currentRoute={location.pathname}/>
                <Outlet/>
                <ModalContainer currentRoute={location.pathname}/>
                <MessageContainer/>
                <PanelContainer/>
                <Overlay mobile={false} onClick={() => dispatch(closeAll())} visibility={overlayVisibility}/>
                <Overlay mobile onClick={() => dispatch(closeAll())} visibility={mobileOverlayVisibility}/>
                <div className="uk-hidden-small">
                    <Alert/>
                </div>
            </div>
        </>
    );
};

export default LayoutContainer;
